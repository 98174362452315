$white: #ffffff;
$black: #000000;

$dark-font: #242A34;
$middle-font: #2C3440;
$light-font: #58677F;
$light-grey-font: #8D8D8D;
$input-font: #2C3440;
$label: #58677F;
$divider: #e8e8e8;

$primary: #2b3547;
$secondary: #2f6381;

$pink: #E35D65;
$bright-blue: #1890ff;
$dark-blue: #2D527C;
$light-blue: #35909A;
$lightest-blue: #B1CEFF;
$light-grey-bg: #f0f2f5;
$sky-blue: #DAEFFF;
$blue-bg: #F8FCFF;
$facebook: #3b5998;