@media only screen and (max-width: 1200px) {
    #stats-page {
        .avg-mark {
            margin-bottom: 1em;
        }
    }

    #home {
        .testimonials {
            &:before {
                left: 33%;
            }
        }
    }

    #study-groups-page {
        .study-groups-list {
            .study-group {
                width: calc(100% / 2 - 0.5em);
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .container {
        padding: 2rem;
    }

    #dashboard {
        .intro {
            padding: 2rem;

            .buttons {
                flex-direction: column;

                .ant-btn {
                    width: 100%;
                    margin: 0;

                    + .ant-btn {
                        margin-top: 0.5rem;
                    }
                }
            }
        }

        .results {

            h1,
            h2 {
                font-size: 3em;
            }
        }

        .stations {
            .station-section {
                padding: 1em;
            }
        }

        .featured-stations-card {
            .ant-card-head {
                padding: 0 1em;
            }

            .ant-card-body {
                flex-direction: column;
                padding: 0 1em;
            }

            .featured-station {
                margin: 0 0 .5em 0;
                padding: .5em 1em;
            }
        }
    }

    .intro {
        h1 {
            font-size: 2em;
        }
    }

    .header {
        height: 48px;
        background: $white;
    }

    .mobile-country-selector {
        display: block;
    }

    .menu-trigger {
        display: inline-block;
    }

    .mobile-menu {
        display: block;
        padding-left: 0;
        text-align: left;
        padding-top: 48px;

        .ant-menu-submenu {
            width: 100%;
        }

        .ant-menu-submenu-title {
            width: 120px;
            float: right;
        }

        &.true {
            display: none;
        }
    }

    .desktop-menu {
        display: none;
    }

    #search {
        .stations {
            max-width: 100%;

            &.has-sidebar {
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 4rem;
            }
        }
    }

    #station {
        .station-content {
            .station-tabs,
            .station-setup {
                &.has-sidebar {
                    max-width: 100%;
                    margin-right: 0;
                }
            }
        }

        .ant-card,
        .ant-tabs {
            width: 100% !important;
        }

        .search-results {
            text-align: right;

            .ant-form-item-label {
                display: none;
            }

            .ant-divider-horizontal {
                margin: 1em 0 0 0;
            }
        }

        .intro {
            flex-direction: row;
            align-items: center;

            h1 {
                flex: 0 1 70%;
                line-height: 1.1em;
                display: flex;
                align-items: center;
            }
        }

        .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
            height: 29px;
        }

        .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
            height: 29px;
            padding: 0 8px;
            font-size: .8em;
            line-height: 25px;
        }

        .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
        .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
            width: 16px;
        }

        .ant-tabs-nav-container-scrolling {
            padding-right: 16px;
            padding-left: 16px;
        }
    }

    .sidenav {
        &.open {
            .ant-drawer-content-wrapper {
                transform: none;
            }
        }

        &.closed {
            .ant-drawer-content-wrapper {
                transform: translateX(100%);
            }
        }
    }

    #study-groups-page {
        .study-groups-list {
            .study-group {
                width: calc(100% - 0.5em);
            }
        }
    }

    .show-drawer {
        display: block;
    }

    .playlists-button-group {
        right: 20px !important;
        bottom: 74px !important;
    }

    .role-title {
        &.solo-study {
            max-width: 100%;
            margin-top: -1rem;
        }
    }

    .tutorial-modal {
        .slider {
            display: block;

            .slider-image {
                width: 100%;
                justify-content: center;

                img {
                    max-width: 50rem;
                }
            }

            .slider-description {
                width: 100%;
                max-width: 50rem;
                margin: 0 auto;
                padding: 1rem 0 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .page-content {
        min-height: calc(100vh - 272px);
    }

    .banner {
        height: 385px;
    }

    #cmsPage {
        .banner {
            height: 200px;

            .container {
                padding: 2em;
            }
        }

        .cms-page-title {
            font-size: 2em !important;
        }

        .cms-page-intro {
            padding-bottom: 1em;

            .intro-title {
                font-size: 1.5em;
            }

            .intro-text {
                -webkit-column-count: 1;
                column-count: 1;
                -webkit-column-gap: 40px;
                column-gap: 40px;
            }
        }
    }

    #not-found-page {
        h1 {
            font-size: 3em;
        }
    }

    #home {
        .banner-text {
            flex-direction: column;
            align-items: flex-start;
            padding: 4em 2em;

            img {
                width: 80px;
                height: 88.5px;
                margin: 0 0 1em 0;
            }

            h1 {
                font-size: 1.6em;
                font-weight: 700;
            }

            p {
                font-size: 1em;
                max-width: 360px;
            }
        }

        .features-section {
            .container {
                padding: 2em;
            }

            .col {
                &:first-child {
                    padding-right: 0;
                }

                &:last-child {
                    align-self: center;
                }
            }

            .row {
                flex-direction: column;
                align-items: flex-start;
            }

            #screenshot {
                margin-top: 3em;
            }

            .feature {
                img {
                    margin-right: 1.5em;
                }
            }
        }

        .how-it-works {
            .container {
                padding: 2em;
            }

            .ant-carousel {
                margin-top: 2em;

                .slide {
                    flex-direction: column;
                    align-items: center;
                    margin-top: 4em;
                }

                .slick-prev {
                    top: 0;
                    left: 45%;
                    transform: translateX(-45%);
                }

                .slick-next {
                    top: 0;
                    left: auto;
                    right: 45%;
                    transform: translateX(45%);
                }

                .slide {
                    flex-direction: column;
                    align-items: center;

                    .slide-img {
                        flex: 0 0 100%;
                    }

                    img {
                        width: 100% !important;
                    }

                    .slide-text {
                        flex: 0 0 100%;
                        padding-left: 0em;
                    }
                }
            }
        }

        .preview-section {
            .container {
                padding: 2em;
            }

            #home-video {
                margin-top: .5em;
                height: 180px;
            }

            .preview-banner {
                flex-direction: column;
                align-items: flex-start;
                padding: 2em;

                button {
                    margin-top: 2em;
                }
            }
        }

        .testimonials {
            &:before {
                top: 8em;
                left: 0;
                transform-origin: top right;
                -ms-transform: skew(0deg, -5deg);
                -webkit-transform: skew(0deg, -5deg);
                transform: skew(0deg, -5deg);
            }

            .heading {
                h1 {
                    line-height: 1.1em;
                }
            }

            .container {
                flex-direction: column;
                align-items: flex-start;
                padding: 2em;
            }

            .ant-carousel {
                flex: 1 1 100%;
                width: 100%;
                margin-top: 4em;
            }
        }

        .subscriptions-heading {
            .container {
                padding: 3em 2em 2em 2em;
            }
        }

        .subscriptions {
            .container {
                flex-direction: column;
                align-items: center;
                padding: 2em;

                .ant-card {
                    width: 100%;
                }
            }
        }
    }

    #plans-pricing {
        .subscriptions {
            flex-direction: column;
            align-items: center;
        }

        .ant-card {
            width: 100%;
        }
    }

    #subscriptions-page {
        .subscriptions {
            .container {
                flex-direction: column;
                align-items: center;
            }

            .ant-card {
                width: 100%;
            }
        }
    }

    #subscriptions-page {
        .subscriptions-list {
            .btn-col {
                margin-top: 2em;
            }
        }
    }

    #dashboard {
        .results {

            h1,
            h2,
            h3 {
                margin: 0;
            }
        }

        .result-col {
            margin: 2em 0 0 0;

            &:first-child {
                margin: 0;
            }
        }

        .stations {
            padding-top: 1rem;
        }
    }

    #blogs {
        .page-title {
            font-size: 2em !important;
        }
    }

    #blog {
        .post-content {
            .post-header {
                flex-direction: column-reverse;
                align-items: flex-start;

                h1 {
                    margin-top: .5em;
                }
            }
        }
    }

    .cms-page-contents {
        .standard-content {
            &:nth-of-type(2n) {
                padding: 1em 0;
            }
        }
    }

    .roles-container {
        margin-bottom: 1em;
    }

    .modal-role-chooser {
        top: 10px;
        width: 90% !important;
    }

    .footer {
        .container {
            padding: 2rem 1rem;
        }

        .row {
            flex-direction: column;
        }

        #logo-footer {
            width: 60px;
            height: 70px;
        }

        .col {
            &:last-child {
                text-align: center;
                margin-top: 1em;
            }
        }

        ul {
            text-align: center;

            li {
                padding: 0 1em;
            }
        }

        .legal-links {
            margin: 1em 0 0 0;
            padding-left: .5em;

            .right-col {
                a {
                    padding: 0 .5em;
                }
            }
        }
    }

    .group-study-details {
        &.horizontal {
            .suggested-stations,
            .suggested-playlists {
                width: 100%;
            }

            .suggested-stations + .suggested-playlists {
                margin-left: 0;
            }
        }
    }

    #study-groups-page {
        .study-groups-list {
            order: 5;

            &.has-sidebar {
                width: 100%;
                margin-top: 2rem;
                margin-right: 0;
            }

            .study-group {
                width: calc(100% / 2 - 0.5em);
            }
        }

        #sidebar {
            width: 100%;
            max-width: 100%;
            order: 4;
        }
    }
}

@media only screen and (max-width: 630px) {
    .centered-buttons {
        .ant-btn {
            width: 100%;
            max-width: 16.25rem;
            margin: 0.5rem auto;
        }
    }
}

@media only screen and (max-width: 600px) {
    .marking-guide-input {
        width: unset;
    }

    #home {
        .testimonials {
            &:before {
                top: 10em;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .container {
        padding: 1rem 1rem 2rem 1rem;
    }

    .page-content {
        min-height: calc(100vh - 320px);
    }

    .intro {
        flex-direction: column;
        align-items: flex-start;

        h1 {
            font-size: 1.2em;
            font-weight: 600;
        }
    }

    #home {
        .how-it-works {
            .ant-carousel {
                .slick-prev {
                    left: 38%;
                    transform: translateX(-38%);
                }

                .slick-next {
                    right: 38%;
                    transform: translateX(38%);
                }
            }
        }
    }

    #dashboard {
        .intro {
            padding: 1rem;
            align-items: center;

            .buttons {
                width: 100%;
                margin: 1rem 0 0;
            }
        }

        .stations {
            h1 {
                font-size: 1.5em;
                font-weight: 600;
            }
        }

        .intro {
            #logo-icon {
                width: 40px;
                height: 44.2px;
            }
        }

        .settings-btn {
            width: 100%;
            margin-top: 1em;

            button {
                width: 100%;
            }
        }
    }

    #stats-page {
        .ant-tabs-content {
            padding: 1em;
        }

        .ant-tabs-tab {
            font-size: .9em;
        }

        #filter-stats-select {
            width: 150px;
        }

        .avg-mark {
            div {
                width: 100%;
            }

            p {
                justify-content: center;
            }
        }
    }

    #subscriptions-page {
        .subscriptions-list {
            .subscription-heading {
                padding: .5em 0;
            }
        }
    }

    .station-display {
        padding: 0;
        flex-direction: column;
        align-items: stretch;

        .station-info {
            h2 {
                font-size: 1.2em;
                line-height: 1.2em;
            }
        }

        .station-actions {
            margin-top: 1em;
        }

        .start-btn {
            height: 40px;
        }

        .playlist-btn {
            margin-top: .5em;
        }

        .tags {
            display: table;
        }

        .ant-tag {
            display: inline-block;
            margin: .75em .5em 0 0;
            text-align: center;
        }

        .station-info {
            h2 {
                margin-right: 2.625rem;
            }
        }

        .station-favourite {
            top: 0; right: 0;
        }
    }

    .sidenav {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }

    #station-sorter {
        display: block;

        .station-searcher {
            max-width: calc(100% - 3rem) !important;
            margin-bottom: 1em;
        }

        .sort-by {
            max-width: calc(100% - 3rem) !important;
        }

        .station-sorter-sorter {
            padding: 0 1em;
            margin-bottom: 0;
        }

        .keyword-searcher {
            max-width: 100%;
            margin: 0 0 1em;
        }
    }

    #login,
    #promotion-page {
        .voucher {

            .ant-form-item,
            #apply-voucher-button {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .footer {
        .container {
            padding: 1.5rem 1rem;
        }

        .legal-links {
            flex-direction: column;
        }

        .right-col {
            padding-top: 1em;
        }

        ul {
            li {
                padding: 0 .5em;

                a {
                    font-size: .85em;
                }
            }
        }
    }

    .gallery {
        .gallery-image {
            max-width: 100%;
        }
    }

    .t-and-c-reminder {
        margin: 2em;
        padding: 1em;
        max-width: 100%;
        margin-top: 50px;
    }

    #study-groups-page {
        .study-groups-list {
            .study-group {
                width: calc(100% - 0.5em);
            }
        }
    }

    .show-drawer {
        width: calc(100% - 2rem);
    }

    .role-title {
        &.solo-study {
            margin-top: -0.5rem;
        }
    }

    .tutorial-modal {
        .slider {
            .slider-description {
                font-size: 90%;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    #station-sorter {
        .ant-btn-sm {
            margin-top: 1em;
        }
    }
}