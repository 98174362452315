@import './variables.scss';



h1,
h2,
h3,
h4,
h5,
h6 {
    color: $middle-font;
    font-weight: 400;
}