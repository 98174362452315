@import '~antd/dist/antd.css';
@import './overwrites.scss';
@import './variables.scss';

html,
body {
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

// Navigation
.ant-menu {
    padding-left: 8rem;

    .ant-menu-item,
    .ant-menu-submenu {
        float: right;
    }

    &.ant-menu-sub {
        .ant-menu-item {
            float: none;
        }
    }
}

.mobile-menu {
    display: none;
    border-bottom: 2px solid $light-blue;
}

#osce-heading {
    position: absolute;
    top: .8rem;
    left: 1.5rem;
    width: 80px;
    height: 19px;
}

.header {
    position: fixed;
    width: 100%;
    z-index: 5;

    .login-menu-item {
        a {
            color: $bright-blue;
            font-weight: 600;
        }
    }

    .group-study-select {
        background-color: $bright-blue;
        color: $white;
    }
}

.menu-trigger {
    display: none;
    position: absolute;
    top: .5rem;
    right: 1.5rem;
}

.container {
    max-width: 90rem;
    margin: 0 auto;
    padding: 3rem 4rem;

    &.fixed {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
    }
}

.page-content {
    margin-top: 48px;
    min-height: calc(100vh - 206px);
}

.footer {
    background: $white;
    z-index: 2;

    .container {
        padding: 2rem 4rem;
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #logo-footer {
        width: 80px;
        height: 94px;
        opacity: .5;
        transition: ease-in-out .2s;

        &:hover {
            opacity: 1;
        }
    }

    .col {
        &:last-child {
            text-align: right;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: right;

        li {
            display: inline-block;
            padding-left: 2em;

            a {
                color: $middle-font;
                font-weight: 500;
                font-size: 1.1em;

                &:hover {
                    color: $bright-blue;
                }
            }
        }
    }

    .legal-links {
        margin: 2em 0 0 0;
        padding-left: 1.75em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-col {
            img {
                width: 30px;
                height: 30px;
                margin: 0 .25em;
                transition: .2s ease-in-out;

                &:hover {
                    opacity: .8;
                }
            }
        }

        .right-col {
            a {
                display: inline-block;
                padding-left: 1em;
                color: $light-font;
                text-decoration: underline;
                font-size: .9em;

                &:hover {
                    color: $primary;
                }
            }
        }

        p {
            margin: 0;
            color: $light-font;
            font-size: .9em;
        }
    }
}

.small-container {
    max-width: 500px;
    margin: 0 auto;
}

#login,
#promotion-page {

    .ant-calendar-picker,
    .ant-select {
        width: 100%;
    }

    .ant-spin {
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-top: 25%
    }

    .ant-form-item {
        margin-bottom: .5rem;
    }

    .ant-btn-sm {
        background-color: #00cc66;
        border-color: #00cc66;
        margin-top: 1em;
    }

    .voucher {
        #apply-voucher-button {
            display: inline-block;
            width: calc(30% - 5px);
            float: right;
        }
    }

    .or {
        display: block;
        text-align: center;
        margin: 1em 0;
        font-weight: 400;
        font-size: 1.2em;
    }

    .promotion-not-available {
        text-align: center;
    }
}

.intro {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-size: 2.5em;
        line-height: 1em;
        font-weight: 300;
        color: $middle-font;
        margin: 0;
        display: flex;
        align-items: center;
    }

    #logo-icon {
        width: 30px;
        height: 30px;
        margin-right: .5em;
    }
}

.banner {
    display: inline-block;
    width: 100%;
    background-size: cover;
    background-position: center center;
    height: 600px;
    position: relative;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#home {
    background: $white;

    .banner-text {
        bottom: 3em;
        display: flex;
        color: $white;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        height: 100%;
        align-items: center;
        padding: 10em 2em 0 4em;

        img {
            margin-right: 3em;
            width: 156px;
            height: 172.5px;
        }

        h1 {
            color: $white;
            font-weight: 600;
            font-size: 2.5em;
            line-height: 1em;
            margin: 0 0 .25em 0;
        }

        p {
            color: $white;
            font-weight: 500;
            font-size: 1.4em;
            max-width: 500px;
            line-height: 1.3em;
            margin-bottom: .75em;
        }

        button {
            span {
                font-weight: 500;
            }
        }
    }

    .features-section {
        background: $blue-bg;

        .container {
            max-width: 1028px;
            padding: 4rem;
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .col {
            &:first-child {
                padding-right: 2em;
                max-width: 450px;
            }
        }

        #screenshot {
            width: 230px;
        }

        h1 {
            font-weight: 600;
        }

        h4 {
            font-size: 1.2em;
            color: $light-font;
        }

        .features {
            margin-top: 3em;
        }

        .feature {
            display: flex;
            align-items: center;
            margin: 2em 0 0 0;

            img {
                margin-right: 2em;
                width: 60px;
                height: 60px;
            }

            h3 {
                margin-bottom: .25em;
                color: $dark-blue;
                font-weight: 500;
            }

            p {
                color: $light-grey-font;
                margin: 0;
            }
        }
    }

    .how-it-works {
        background: linear-gradient(180deg, $light-blue 0%, $secondary 100%);

        .container {
            max-width: 1028px;
        }

        h1 {
            text-align: center;
            color: $white;
            font-weight: 600;
        }

        .ant-carousel {
            margin-top: 3em;

            .slick-prev {
                width: 40px;
                height: 40px;
                z-index: 1;
                left: calc(100% - 343px);
                top: 65%;
                opacity: .5;
                transition: ease-in-out .2s;

                &:hover {
                    opacity: 1;
                }

                &:before {
                    content: '';
                    background-image: url(../images/arrow-prev.svg);
                    background-size: 40px 40px;
                    background-position: center center;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                }
            }

            .slick-next {
                width: 40px;
                height: 40px;
                z-index: 1;
                left: calc(100% - 285px);
                top: 65%;
                opacity: .5;
                transition: ease-in-out .2s;

                &:hover {
                    opacity: 1;
                }

                &:before {
                    content: '';
                    background-image: url(../images/arrow-next.svg);
                    background-size: 40px 40px;
                    background-position: center center;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                }
            }

            .slick-slide,
            .slick-slide>div,
            .slide {
                min-height: 330px;
            }
        }

        .slide {
            display: flex !important;
            justify-content: space-between;

            img {
                display: inline-block;
                width: 350px;
            }

            .slide-img {
                flex: 0 0 50%;
                text-align: center;
                align-self: center;
            }

            .slide-text {
                flex: 0 0 50%;
                padding-left: 2em;
                padding-top: 3em;
                max-width: 370px;

                h3 {
                    color: $white;
                    font-weight: 600;
                }

                p {
                    color: $white;
                    border-top: 1px solid rgba($white, .2);
                    padding-top: 1.5em;
                    margin: 1.5em 0 0 0;
                }
            }
        }
    }

    .preview-section {
        background: $blue-bg;

        .container {
            max-width: 1028px;
        }

        h1 {
            text-align: center;
            color: $dark-blue;
            font-weight: 600;
        }

        .wistia_responsive_padding {
            -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.15);

            iframe {
                border-radius: 4px;
            }
        }

        .preview-banner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3em 4em;
            width: 100%;
            margin-top: 3em;
            background: $bright-blue;
            -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.15);
            border-radius: 4px;

            h2 {
                font-weight: 600;
                color: $white;
            }

            p {
                color: $white;
                max-width: 300px;
                margin: 0;
            }

            button {
                border: none;

                span {
                    font-weight: 500;
                }
            }
        }
    }

    .testimonials {
        background: $sky-blue;
        position: relative;
        overflow: hidden;
        width: 100%;

        * {
            min-height: 0;
            min-width: 0;
        }

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 40%;
            background: $dark-blue;
            transform-origin: bottom left;
            -ms-transform: skew(-25deg, 0deg);
            -webkit-transform: skew(-25deg, 0deg);
            transform: skew(-25deg, 0deg);

        }

        .container {
            display: flex;
            align-items: center;
            max-width: 1028px;
        }

        .heading {
            flex: 0 0 50%;

            h1 {
                max-width: 200px;
                font-weight: 600;
                color: $dark-blue;
                text-align: right;
            }

            img {
                display: table;
                margin-left: auto;
                width: 48px;
                height: 48px;
                margin-bottom: .5em;
            }
        }

        .ant-carousel {
            flex: 0 0 50%;
            width: 50%;

            .slick-dots {
                position: initial;
                height: auto;
                text-align: right;

                li {
                    vertical-align: middle;
                    margin: 0 4px;
                }

                button {
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 20px;
                }
            }

            .slide {
                p {
                    color: $white;
                    font-size: 1.1em;
                    font-weight: 500;
                }
            }
        }
    }

    .subscriptions-heading {
        text-align: center;
        padding: 2rem 2em 0 2em;

        .container {
            padding-bottom: 2rem;
        }

        h1 {
            font-weight: 600;
            color: $dark-blue;
            margin: 0;
        }

        p {
            color: $light-grey-font;
            font-size: 1.1em;
            margin: 0;
        }
    }

    .subscriptions {
        background: $blue-bg;
        margin-top: 3rem;
        display: inline-block;
        width: 100%;

        .container {
            max-width: 1028px;
            display: flex;
            align-items: stretch;
            justify-content: center;
            padding-top: 2rem;
        }

        .ant-card {
            max-width: 320px;
            flex: 1 1 320px;

            .ant-card-body {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            a {
                margin-top: auto;
            }

            h1 {
                font-weight: 700;
                margin: 0;
            }
        }
    }
}

// Country Selector

.ant-menu {
    img {
        height: 1rem;
        margin-right: 0.5rem;
    }
}

button.current-country {
    position: relative;
    background: transparent;
    padding: 0;
    border: 0 none;
    white-space: nowrap;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        top: 50%; right: 0.25rem;
        width: 0.25rem;
        height: 0.25rem;
        border: 1px solid transparent;
        border-right-color: rgba($black, 0.5);
        border-bottom-color: rgba($black, 0.5);
        transform: rotate(45deg) translate(-50%, -50%);
    }

    &:hover {
        color: $bright-blue;

        &:after {
            border-right-color: $bright-blue;
            border-bottom-color: $bright-blue;
        }
    }

    img {
        margin-right: 1.125rem;
    }
}

.mobile-country-selector {
    display: none;
    padding-right: 5rem;
}

#dashboard {
    .intro {
        padding: 2rem 4rem;

        .ant-skeleton-active {
            .ant-skeleton-title,
            .ant-skeleton-avatar {
                background: linear-gradient(90deg, #f2f2f2 25%, #b6d8ff 37%, #f2f2f2 63%);
                animation: ant-skeleton-loading 1.4s ease infinite;
                background-size: 400% 100%;
            }
        }

        #logo-icon {
            width: 100px;
            height: 111px;
        }

        .buttons {
            display: flex;
            margin-left: 1rem;
            align-items: center;

            .ant-btn {
                margin: 0 0 0 0.5rem;
            }

            .subscription-btn {
                background: $light-blue;
                border-color: $light-blue;
                line-height: 37px;
                color: $white;

                &:hover {
                    background: $dark-blue;
                }
            }
        }
    }

    .results {
        text-align: center;
        padding: 1em 0;
        background: linear-gradient(180deg, $light-blue 0%, $secondary 100%);

        h3 {
            color: $white;
            margin: 0;
        }

        h1,
        h2,
        .ant-skeleton-title {
            color: $white;
            font-size: 4em;
            font-weight: 600;
            letter-spacing: 1px;
            margin: .25em 0 0 0;
            line-height: 1.1em;

            .ant-skeleton-title {
                margin-left: auto;
                margin-right: auto;
                background: linear-gradient(90deg, #f2f2f2 25%, #b7b7b7 37%, #f2f2f2 63%);
                /*Colours changed here to be easier to see on background - change to something that goes better with blue?*/
                animation: ant-skeleton-loading 1.4s ease infinite;
                background-size: 400% 100%;
            }
        }
    }

    .featured_stations-stations {
        padding-top: 3em;

        h1 {
            text-align: center;
            font-size: 2.5em;
            margin-bottom: 1em;
        }

        .station-section {
            background: $white;
            padding: 2em;
            border-radius: 3px;
            cursor: pointer;
            height: 100%;

            h2 {
                margin: 0 0 1em 0;
                border-bottom: 1px solid $lightest-blue;
                padding-bottom: .75em;
                font-weight: 600;
                color: $light-font;
            }

            img {
                width: 30px;
                height: 30px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 1em;
            }

            p {
                letter-spacing: .5px;
                font-weight: 500;
                margin: 1em 0 0 0;
            }
        }
    }

    .featured-stations-card {
        width: 100%;
        display: inline-block;
        margin-bottom: 2em;
        background: transparent;
        border: none;
        padding-bottom: 1.5em;

        .ant-card-head {
            border: none;
        }

        .ant-card-head-title {
            text-align: center;
            font-size: 1.4em;
            font-weight: 600;
            color: $light-blue;
        }

        .ant-card-body {
            display: flex;
            margin: 0 -1em;
            padding: 0 24px;
        }

        .featured-station {
            flex: 1 1 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $light-blue;
            padding: 1em 2em;
            margin: 0 1em;
            border-radius: 4px;
            background: $white;
        }

        .ant-btn {
            border-color: $light-blue;
            background: $light-blue;
            color: $white;

            &:hover {
                background: $dark-blue;
            }
        }

        .ant-row {
            margin-bottom: 1em;
        }

        p {
            margin: 0;
            display: flex;
            line-height: 1.1em;
            align-items: center;
            padding-right: .5em;
            letter-spacing: .5px;
            font-weight: 500;
            font-size: 14px;

            b {
                margin-right: 1em;
                color: $light-font;
                font-size: 1.3em;
            }
        }
    }
}

.role-chooser {
    display: flex;
    align-items: center;
    border: $lightest-blue solid 1px;
    border-radius: 5px;
    padding: 0.6em;
    margin: 1em 0;
    transition: .25s ease-in-out;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    background: $white;

    &.selected {
        background-color: #eaeaea;
        color: #797575;
        border-color: #797575;
    }

    &.primary {
        background-color: #1890ff;
        border-color: #1890ff;
        color: #fff;
    }

    .selected-by {
        font-size: 10px;
        display: block;
        margin-left: 15%;
    }

    &.next-playlist-btn {
        background: $bright-blue;
        border-color: $bright-blue;
        font-weight: 600;
        color: $white;

        i {
            color: $white;
        }
    }

    &:hover {
        transform: translate(0px, -1px);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    }

    &.danger {
        border-color: $pink;
    }

    img {
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;
    }

    i {
        width: 30px;
        margin: 0 1em 0 0;
    }
}

.study-time {
    h1 {
        text-align: center;
        font-size: 2.5em;
    }

    .buttons {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        flex-wrap: wrap;

        .button {
            background-color: #35909A;
            height: auto;
            margin: 0.5em;
            padding: 0.8em 2em;
            border-color: #35909A;
            line-height: 1;
            font-size: 1.75em;

            @media only screen and (max-width: 400px) {
                font-size: 1.25em;
            }
        }
    }
}

.search-sidenav,
#stats-page {
    .search-button {
        min-width: 100%;
    }
}

#playlist {
    .playlist {
        + .playlist {
            margin-top: 0.5rem;
        }
    }

    .playlist-title {
        padding: 0;
        border: 1px solid $divider;
        border-radius: 0.25rem;
        flex-wrap: wrap;

        &.active {
            background: $bright-blue;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-color: $bright-blue;
            color: $white;

            .toggle-playlist {
                color: $white;
            }
        }

        .ant-col {
            display: flex;

            &:last-child {
                margin-left: auto;
                flex-shrink: 0;
            }
        }

        .playlist-name {
            max-width: 12.75rem;
            padding: 0.625rem 0.75rem 0.75rem;
            cursor: pointer;
        }

        .delete {
            margin-right: 0.25rem;
            margin-left: 0.5rem;
        }

        .toggle-playlist {
            width: auto;
            margin-right: 0.25rem;
            padding: 0 0.25rem;
            line-height: 0;
            font-size: 22px;

            svg {
                display: block;
            }
        }

        .actions {
            padding-right: 0.375rem;
        }
    }

    .playlist-item {
        padding: 1rem 0.75rem 1rem 1rem;
        border-right: 1px solid $divider;
        border-bottom: 1px solid $divider;
        border-left: 1px solid $divider;

        .playlist-item-name {
            margin-right: 1rem;
            line-height: 1.1em;
            font-size: 120%;
            color: #1890ff;
        }

        .playlist-item-description {
            margin-right: 1rem;
        }

        p {
            margin: 0;
        }
    }

    .playlist-footer {
        background: darken($white, 3%);
        padding: 0.5rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-right: 1px solid $divider;
        border-bottom: 1px solid $divider;
        border-left: 1px solid $divider;
        text-align: right;

        .playlist-button {
            // font-size: 80%;
        }
    }
}

.playlist-name {
    display: flex;

    input,
    .ant-select {
        margin-right: 0.5em;
    }
}

.notes-and-marking {
    .start-btn {
        margin-bottom: 2em;
    }

    .display-info {
        .role-img {
            max-width: 25px;
        }

        .notes-scores-display {
            user-select: none;

            #marking-guide {
                .ant-checkbox-wrapper+.ant-checkbox-wrapper {
                    margin-left: 2px;
                }

                .ant-checkbox-group {
                    width: 100%;
                }

                .ant-btn-group {
                    margin-bottom: 2em;
                }

                p {
                    margin-bottom: 0;
                }

                .marking-guide-content {
                    flex-flow: row;
                    padding: .15em 0;

                    .marking-guide-checkboxes {
                        flex: 1 0 55px;
                        min-width: 55px;
                        text-align: right;
                        margin-right: .75em;

                        .marking-checkbox {
                            :hover {
                                cursor: initial;
                            }
                        }
                    }

                    .marking-guide-task {
                        flex: 1 1 100%;
                    }
                }

                .marking-guide-input {
                    width: 120px;

                    .ant-input-group-addon {
                        width: 50%;
                    }
                }
            }

            .examiner-notes {
                margin-top: 2rem;
            }
        }
    }
}

.station-container {
    width: 100%;
}

.station-display {
    display: flex;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 2em;
    justify-content: space-between;
    align-items: flex-end;

    td {
        padding: .25em 0;
    }

    .table-heading {
        padding-right: 2em;
        vertical-align: top;
    }

    .table-data {
        font-weight: 500;
        color: $dark-font;
    }

    .ant-tag {
        vertical-align: middle;
        margin-left: 1em;

        &.status-tag {
            background: $white;
            border-style: dashed;
            margin-left: 0;
        }
    }

    .playlist-btn {
        background: transparent;
        box-shadow: none;
        border: none;
        padding: 0;
    }

    .station-info {
        flex: 1 1;

        span {
            vertical-align: middle;
        }

        b {
            margin-right: .5em;
            vertical-align: middle;
        }
    }

    .station-actions {
        /*flex: 0 0 85px;*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
            display: block;
            width: 100%;

            &.unavailable {
                background-color: #B1B1B1;
                border-color: #aca8a8;
            }
        }

        .percentage {
            max-width: none;
            text-align: center;
            margin: 0 0 1em 0;
            padding: .322em 1em;
            background: none;
            border: 1px solid $lightest-blue;
        }
    }

    .station-favourite {
        position: absolute;
        top: 2rem; right: 2rem;
        padding: 5px;
        margin-left: auto;
        border: 1px solid #efb528;
        border-radius: 15px;

        &:hover {
            cursor: pointer;
        }
    }
}

.show-drawer {
    display: none;
    position: absolute;
    bottom: 2rem;
    width: calc(100% - 4rem);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.75em;
    z-index: 3;

    &.pinned {
        position: fixed;
        background: #f0f2f5;
        right: 0; bottom: 0; left: 0;
        width: 100%;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    }
}

#station {
    .container {
        position: relative;
        padding-bottom: 6rem;
    }

    .station-content {
        display: flex;
        padding-top: 1em;
        align-items: flex-start;

        .station-setup {
            width: 100%;
            // max-width: 800px;
            border-radius: 3px;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

            &.has-sidebar {
                max-width: calc(100% - 24.75rem);
                margin-right: 2.5rem;
            }
        }

        .station-complete {
            .role-chooser img {
                height: 18px;
            }
        }

        .station-marking {
            text-align: center;

            .percentage {
                width: 100%;
                max-width: 15rem;
            }
        }
    }

    .station-tabs {
        width: 100%;

        &.has-sidebar {
            max-width: calc(100% - 24.75rem);
            margin-right: 2.5rem;
        }

        .ant-tabs {
            border-radius: 3px;
            background: transparent;

            &.no-sidenav {
                width: 100%;
                max-width: 800px;
            }
        }

        .ant-tabs-bar {
            margin: 0;
            font-weight: 600;
        }

        .ant-tabs-content {
            background: $white;
            -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
            padding: 2em;
            border: 1px solid #e8e8e8;
            border-top: none;
        }

        .ant-tabs-tabpane {
            max-width: 800px;
            margin: 0 auto;
        }

        #marking-guide {
            .ant-checkbox-wrapper+.ant-checkbox-wrapper {
                margin-left: 2px;
            }

            .ant-checkbox-group {
                width: 100%;
            }

            .ant-btn-group {
                margin-bottom: 2em;
            }

            p {
                margin-bottom: 0;
            }

            .marking-guide-content {
                flex-flow: row;
                padding: .15em 0;

                .marking-guide-checkboxes {
                    flex: 1 0 55px;
                    min-width: 55px;
                    text-align: right;
                    margin-right: .75em;
                }

                .marking-guide-task {
                    flex: 1 1 100%;
                }
            }
        }
    }

    .ant-card-head {
        background: $secondary;
    }

    button {
        &.next {
            margin-top: 2em;
            float: right;
            display: table;
        }
    }

    div {
        user-select: none;
    }

    .marking-guide-input {
        width: 120px;

        .ant-input-group-addon {
            width: 50%;
        }
    }

    #sidebar {
        .sidenav {
            margin-top: 2.5rem;
        }
    }
}

.timer-presets {
    padding-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#search {
    .container {
        display: flex;
        position: relative;
        max-width: 90rem;
        margin: 0 auto;
        flex-wrap: wrap;
    }

    #station-sorter_sort_type {
        margin-left: auto;
    }

    .stations {
        width: 100%;
        max-width: 50rem;
        margin-right: auto;
        margin-left: auto;
        border-radius: 3px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

        &.has-sidebar {
            max-width: calc(100% - 24.75rem);
            margin-right: 2.5rem;
            margin-left: 0;
        }
    }

    .ant-card-body {
        margin: 0 auto;
    }
}

#sidebar {
    width: 100%;
    max-width: 22.25rem;
}

.role-title {
    margin: 0.75rem 0 0;
    padding: 1em 0;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: .15px;
    font-weight: 600;
    font-size: .85em;
    cursor: pointer;
    color: $light-blue;

    &:hover {
        color: $primary;
    }

    &.solo-study {
        width: 100%;
        max-width: 50rem;
        margin: -2rem auto 0;
        padding: 1em;
        text-align: right;
    }

    img {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 0.75em;
        vertical-align: middle;
    }

    span {
        white-space: nowrap;
    }
}

.role-title + .group-study-details {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid #ccc;
}

.search-line {
    width: 100%;
    margin-bottom: 1em;
    padding-top: .5em;

    .ant-col {
        &:nth-child(1) {
            flex: 1 1;
        }

        &:nth-child(2) {
            flex: 0 0 40px;
        }
    }

    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.search-results {
    p {
        margin: 0;
        text-transform: uppercase;
    }

    .ant-form-item {
        margin-right: 0;
    }

    .ant-divider-horizontal {
        margin: 2em 0 0 0;
    }
}

.filters {
    .ant-checkbox-wrapper {
        display: table;
        margin: 0 !important;
    }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .clear-filter {
            color: rgba($light-font, .5);
            cursor: pointer;

            &:hover {
                color: rgba($light-font, 1);
            }
        }

        .filter-name {
            font-weight: 500;
        }

        &.main-heading {
            .filter-name {
                text-transform: uppercase;
            }
        }

        p {
            margin-bottom: .3em;
        }
    }

    .ant-form-item {
        margin-bottom: .5em;
    }

    .checkbox-item {
        margin-bottom: 0;
    }

    .ant-divider-horizontal {
        margin: .5em 0;
    }

    .ant-select-search__field__wrap {
        padding: 0 5px;

        .ant-select-search__field {
            padding: 0;
        }
    }

    .ant-form-item,
    .ant-select,
    .ant-select-search__field,
    .ant-select-dropdown {
        font-size: 16px !important;
    }
}

.modal-role-chooser {
    .ant-modal-confirm-content {
        margin: 2em 0 0 0 !important;
        border-top: 1px solid $lightest-blue;
        padding-top: 2em;
    }
}

.overflow-initial {
    overflow: initial !important;
}

.sidenav {
    &.search-sidenav {
        .ant-drawer-header-no-title {
            .ant-drawer-close {
                margin-top: 45px;
            }
        }
    }

    &.time-nav {
        z-index: 5;
    }

    .current-state {
        margin: 0;
        font-weight: 500;
        color: $light-font;
    }

    .time-value {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .timing {
            margin: 0;
            background: $lightest-blue;
            color: $light-font;
            font-weight: 600;
            border-radius: 3px;
            padding: .4em 1em;
        }
    }

    .show-all-switch {
        margin-bottom: 1em;
        font-weight: 500;
        color: #58677F;
        font-size: 16px;
        line-height: 1.5;

        .ant-switch {
            margin-right: 10px;
        }
    }

    .sync-timers {
        margin-bottom: 1rem;
    }

    .timing-controls {
        text-align: center;
        padding-top: 15px;

        .ant-btn-group {
            width: 100%;
        }

        button {
            width: 20%;
            height: 40px;
        }
    }

    .timer-row {
        padding: .5em .75em;
        border-radius: 3px;

        h3 {
            color: #c6c6c6;
        }

        .timing {
            background: #DFEBFF;
            color: #c6c6c6;
        }
    }

    .timer-row-active {
        background-color: $white;
        border-radius: 3px;
        padding: .5em .75em;
    }

    .ant-drawer-content {
        background: #f0f2f5;
    }

    .ant-drawer-body {
        padding: 1em 1em 5em 1em;
    }

    .btn-controls {
        button {
            margin-top: .5em;
        }
    }


    .station-nav-buttons {
        width: 100%;
        text-align: center;
    }

    &.search-sidenav {
        .ant-drawer-body {
            padding: 2em 1.5em;
        }
    }

    .form-buttons {
        width: 100%;
        margin: 1em auto 0;

        button {
            width: 50%;
            font-size: 98%;
        }
    }
}

#stats-page {
    .ant-tabs-bar {
        margin: 0;
    }

    .ant-tabs-content {
        padding: 2em;
        border-radius: 3px;
        background: $white;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
    }

    .ant-tabs-tab {
        line-height: 48px;
        height: 48px;
        font-size: 1.1em;
    }

    .ant-tabs-nav-container {
        height: 48px;
    }

    .chart-container {
        margin: 2em 0;
        position: relative;
        width: 100%;
        height: 500px;

        canvas {
            height: 100%;
        }

        @media only screen and (max-width: 768px) {
            height: 300px;
        }
    }

    .avg-mark {
        text-align: right;
        line-height: normal;
        margin-bottom: -48px;

        div {
            display: inline-block;
            background: rgba($light-blue, .15);
            color: $light-blue;
            border-radius: 3px;
            -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
            -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
            padding: .965em;
        }

        p {
            display: flex;
            align-items: center;
            text-align: left;
            font-size: .8em;
            line-height: .9em;
            margin: 0;
            font-weight: 500;

            b {
                font-size: 2.1em;
                margin-left: 10px;
            }
        }
    }

    .station-display {
        align-items: stretch;
    }

    #reset-stats-button {
        float: right;
    }

    #filter-stats-select {
        margin-bottom: 1em;
        width: 200px;
    }

    #filter-history-select {
        margin-bottom: 1em;
        width: 200px;
        margin-left: 1em;
    }
}

#not-found-page {
    text-align: center;

    h1 {
        font-size: 5em;
        margin: 0;
    }
}

#settings {

    .ant-calendar-picker,
    .ant-select {
        width: 100%;
    }

    .ant-spin {
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-top: 25%;
    }
}

.subscriptions {
    .ant-card {
        display: inline-block;
        width: 100%;
        max-width: 350px;
        border: none;
        border-radius: 4px;
        margin: .5em;
        -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &:hover {
            -webkit-box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.1);
        }

        &.trial {
            background: $light-blue;
            border-color: $light-blue;

            .price {
                color: $white;
            }

            .time-period {
                color: $white;
            }

            .ant-btn-primary {
                background: $white;
                color: $light-blue;

                &:hover {
                    background-color: #dfdfdf;
                }
            }
        }

        .subscribe-circle {
            background-color: $white;
            border-radius: 70px;
            margin: 0px auto;
            max-width: 140px;
            max-height: 140px;
            padding: 10px;
        }

        .ant-card-body {
            height: 100%;
        }

        img {
            width: 50px;
            height: 50px;
            display: table;
            margin: 0 auto 1em auto;
        }

        h1 {
            text-align: center;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $dark-blue;
        }

        .cost {
            display: flex;
            align-items: baseline;
            justify-content: center;
        }

        .price {
            font-size: 2em;
            color: $light-blue;
            font-weight: 700;
        }

        .currency {
            color: $light-blue;
            font-weight: 700;
            font-size: initial;
        }

        .discounted-price {
            font-size: .5em;
            font-weight: 400;
            text-decoration: line-through;
            display: inline-block;
            vertical-align: baseline;
            color: rgba($light-font, .6);
        }

        .time-period {
            margin-left: .5em;
        }

        .description {
            color: $dark-font;
            font-size: 1.1em;
        }

        .ant-divider-horizontal {
            margin: 15px 0;
        }

        .button-selected,
        .button-select {
            position: absolute;
            top: -10px;
            right: -10px;
        }

        button:not(.circle-btn) {
            width: 100%;
            margin-top: 1em;
            background: $light-blue;
            border-color: $light-blue;

            &:hover {
                background: rgba($light-blue, .9);
                border-color: rgba($light-blue, .9);
            }
        }

        .circle-btn {
            &.button-selected {
                border: 3px solid $light-blue;

                .anticon {
                    color: $light-blue;
                }
            }

            &.button-select {
                border: 3px solid $dark-blue;
            }
        }
    }
}

.contact-form,
.report-issue-form,
.account-form,
.change-password-form,
.purchase-subscription-form,
.login-form {
    max-width: 500px;
    margin: 0 auto;
}

.signup-heading {
    text-align: center;
    margin-bottom: 1em;
    color: $light-blue;
    font-size: 1.75em;
    font-weight: 600;
}

.signup-form {
    .ant-form-item {
        margin-bottom: 10px;
    }

    #signup_subscription_type_id {
        display: flex;
    }

    .fb-button {
        background-color: $facebook;
        border: none;
        height: 40px;
        width: 100%;
        max-width: 250px;
        display: table;
        margin: 0 0 1em 0;
        border-radius: 4px;
        cursor: pointer;
        transition: ease-in-out .2s;

        &:hover {
            background-color: rgba($facebook, .9);
        }

        span {
            color: $white;
            font-size: 16px;
        }

        b {
            font-weight: 500;
        }

        .anticon {
            padding-right: .5em;
        }
    }

    .success {
        color: $primary;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 1.2em;

        .anticon {
            padding-right: .5rem;
            font-size: 2em;
        }
    }

    .signup-btn {
        margin-top: 2em;
        max-width: 250px;
    }

    @media only screen and (max-width: 1200px) {
        #signup_subscription_type_id {
            flex-direction: column;
        }
    }

    @media only screen and (max-width: 768px) {
        #signup_subscription_type_id {
            flex-direction: row;
        }

        .subscriptions-col {
            margin-top: 2em;
        }
    }

    @media only screen and (max-width: 576px) {
        #signup_subscription_type_id {
            flex-direction: column;
            align-items: center;
        }

        .fb-button,
        .signup-btn {
            max-width: none;
        }
    }
}

.StripeElement {
    background: $white;
    padding: .75rem;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
}

#paypal-payment {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding-top: 2em;

    .ant-btn {
        margin-top: 2em;
    }
}

.login-form {
    max-width: 350px;

    .fb-button {
        background-color: $facebook;
        border: none;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        cursor: pointer;
        transition: ease-in-out .2s;

        &:hover {
            background-color: rgba($facebook, .9);
        }

        span {
            color: $white;
            font-size: 16px;
        }

        b {
            font-weight: 500;
        }

        .anticon {
            padding-right: .5em;
        }
    }
}

#auth-errors {
    background-color: white;
    border-radius: 5px;
    border: solid 1px;
    padding: 1em;
    margin-top: 10px;
    color: #f5222d;
    border-color: #f5222d;
}

#activate {
    h2 {
        text-align: center;
        padding-top: 5%;
    }

    p {
        font-size: 1.2em;
        text-align: center;
    }

    .ant-spin {
        padding-top: 10%;
    }

    .email-submit-success {
        text-align: center;
        margin-top: 2em;
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;
        background-color: $white;
        border: 1px solid #00cc66;
        padding: 0.5em;
    }

    .password-reset-error {
        text-align: center;
        margin-top: 2em;
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;
        background-color: $white;
        border: 1px solid #ff944d;
        padding: 0.5em;
    }
}

.percentage {
    background: #2F6381;
    max-width: 30%;
    margin: 0 auto 2rem;
    padding: .4em 1em;
    border-radius: 3px;
    font-weight: 600;
}

.low {
    color: #ff6971;
}

.medium {
    color: #ff944d;
}

.high {
    color: #00cc66;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.centered-buttons {
    display: block;
    padding-top: 0.75rem;
    text-align: center;

    .ant-btn {
        margin: 0.5rem 0.5rem;

        &.danger-button {
            background: #ff4d4f;
            border-color: #ff4d4f;
            color: #fff;
        }
    }
}

.loading {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 30%;
}

.ant-button-selected {
    background-color: #ff944d;
    border-color: #ff944d;
}

.skeleton-display {
    border-bottom: 1px solid #e8e8e8;
}

#station-sorter {
    display: flex;
    padding-bottom: 1em;
    border-bottom: 1px solid $divider;
    align-items: center;

    .station-searcher {
        max-width: 10rem;
    }

    .sort-by {
        max-width: 10rem;
    }

    button {
        width: 2rem;
        margin-left: 1rem;
        padding-right: 8px;
        padding-left: 8px;
    }

    .keyword-searcher {
        max-width: 13.5rem;
        margin-right: auto;
    }
}

#cmsPage {
    .ant-skeleton-active .ant-skeleton-content {

        .ant-skeleton-paragraph li,
        .ant-skeleton-title {
            background: linear-gradient(90deg, #e6e6e6 25%, #f2f2f2 37%, #d8d8d8 63%);
            animation: ant-skeleton-loading 1.4s ease infinite;
            background-size: 400% 100%;
        }
    }

    .cms-page-title {
        font-size: 3.5em;
        font-weight: 600;
        margin: 0;
    }

    .banner {
        display: flex;
        align-items: center;
        height: 300px;

        .cms-page-title {
            width: 100%;
            color: $white;
            font-size: 3.5em;
            font-weight: 600;
            margin: 0;
        }
    }

    .cms-page-intro {
        border-bottom: 2px solid $divider;
        padding-bottom: 2em;

        .intro-title {
            font-size: 2em;
            font-weight: 500;
        }

        .intro-text {
            -webkit-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 40px;
            column-gap: 40px;
            font-size: 1.2em;
        }
    }
}

#subscriptions-page {
    .subscriptions-list {
        .ant-card {
            display: inline-block;
            width: 100%;
            border: none;
            border-radius: 4px;
            margin-bottom: 1em;
            -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .subscriptions {
        background: $blue-bg;

        .container {
            display: flex;
            align-items: stretch;
            justify-content: center;
            padding-top: 2em;
        }
    }

    .subscriptions-heading {
        padding: 3em 2em 0 2em;

        p {
            color: $light-grey-font;
            font-size: 1.1em;
            max-width: 520px;
            text-align: center;
            margin: 0 auto;
        }
    }

    .subscription-heading {}

    .button-selected {
        position: initial;
        background: rgba($pink, .9);
        border-color: rgba($pink, .9);
    }

    .active {
        color: #008BFF;
    }

    .pending,
    .cancelled,
    .expired {
        color: #B1B1B1;
    }

    .ant-tag {
        margin-left: 2em;
    }

    .ant-spin {
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-top: 25%;
    }

    #paypal-payment {
        padding: 0;
        text-align: left;
    }

    .payment-section {
        background: $blue-bg;

        .container {
            padding-top: 0;
        }

        .ant-divider {
            margin: 0 0 3em 0;
        }
    }

    .voucher {
        #apply-voucher-button {
            display: inline-block;
            width: calc(30% - 5px);
            float: right;
        }
    }

    .success {
        color: $primary;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 1.2em;

        .anticon {
            padding-right: .5rem;
            font-size: 2em;
        }
    }
}

#preview {
    .preview-heading {
        text-align: center;
        padding-bottom: 2em;

        h1 {
            font-weight: 600;
            color: $dark-blue;
            margin: 0;
        }
    }

    p.heading {
        color: $light-grey-font;
        font-size: 1.1em;
    }

    .station-section {
        background: $white;
        padding: 2em;
        border-radius: 3px;
        cursor: pointer;
        height: 100%;
        text-align: left;

        h2 {
            margin: 0 0 1em 0;
            border-bottom: 1px solid $lightest-blue;
            padding-bottom: .75em;
            font-weight: 600;
            color: $light-font;
        }

        img {
            width: 30px;
            height: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 1em;
        }

        p {
            letter-spacing: .5px;
            font-weight: 500;
            margin: 1em 0 0 0;
        }
    }
}

#plans-pricing {
    text-align: center;

    .subscriptions {
        display: flex;
        align-items: stretch;
        justify-content: center;
        padding-top: 2em;
    }

    h1 {
        font-weight: 600;
        color: $dark-blue;
        margin: 0;
    }

    p {
        color: $light-grey-font;
        font-size: 1.1em;
    }

    .ant-card {

        &.trial {
            .description {
                p {
                    color: $white;
                }
            }
        }

        max-width: 320px;
        flex: 1 1 320px;

        .ant-card-body {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        a {
            margin-top: auto;
        }

        h1 {
            font-weight: 700;
            margin: 0;
        }

        .description {
            p {
                color: $dark-font;
                font-size: 1.1em;
                text-align: left;
            }
        }
    }
}

#blogs {
    .page-title {
        font-size: 3.5em;
        font-weight: 600;
        margin: 0 0 1em 0;
    }

    .blog-thumbnail {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 1em;
    }

    .ant-row {
        display: flex;
        flex-flow: row wrap;
    }

    .ant-col {
        margin-bottom: 20px;
    }

    .ant-card {
        border-radius: 3px;
        border: none;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
        height: 100%;

        &:hover {
            -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
        }
    }

    .ant-card-head-wrapper {
        border-bottom: 1px solid $divider;
    }

    .ant-card-head {
        border-bottom: 0;
    }

    .ant-card-head-title {
        overflow: auto;
        white-space: initial;
        text-overflow: initial;
    }

    .ant-list-grid .ant-list-item {
        margin-bottom: 0;
        height: 100%;
    }

    .ant-tag {
        margin: 3px 6px 3px 0;
    }
}


#blog {
    .container {
        max-width: 62rem;
    }

    .post-content {
        background: $white;
        padding: 2em;
        border-radius: 4px;

        .post-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto 2em auto;

            h1 {
                padding-right: 2em;
                font-size: 2em;
                line-height: 1.4em;
                font-weight: 500;
                color: $dark-blue;
                margin: 0;
            }

            .anticon {
                font-size: 10px;
                line-height: 12px;
                vertical-align: middle;
            }
        }

        .post-featured-img {
            display: table;
            width: 100%;
            height: auto;
            margin: 2em auto;
        }
    }
}

.cms-page-contents {
    .standard-content {
        padding: 1rem 0;

        &.ant-row {
            margin-left: -1rem !important;
            margin-right: -1rem !important;
        }

        &:nth-of-type(2n) {
            background: $white;
            padding: 2em;
            margin: 1em 0;
            border-radius: 4px;
        }
    }
}

.gallery {
    padding: 1em 0;

    .gallery-image {
        max-width: 50%;
        padding: 0.5em;
    }
}

.accordion {
    padding: 1em 0;

    .accordion-name {
        margin: 0;
    }

    .accordion-description {
        color: $light-grey-font;
        font-size: 1.1em;
    }

    .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
        padding-top: 16px;
    }

    .ant-collapse-content-box {
        background: $white;
    }

    .ant-collapse {
        background-color: transparent;
    }

    .ant-collapse-content {
        border-radius: 0 0 4px 4px;
    }

    .ant-collapse-header {
        background-color: $white;
        border-radius: 4px !important;
        font-size: 1.1em;
        font-weight: 500;
        border-bottom: 1px solid $divider;
        color: $light-font !important;
    }

    .ant-collapse-item {
        margin-bottom: 1em;
        border-bottom: 0;
        -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.05);

        &.ant-collapse-item-active {
            .ant-collapse-header {
                background-color: $white;
                border-radius: 4px 4px 0 0 !important;
            }
        }
    }
}

.t-and-c-reminder {
    text-align: center;
    margin: 2em;
    padding: 1em;
    background-color: #b3ccff;
    border-radius: 5px;
    border: 1px #000080 solid;
    display: block;
    margin-left: auto;
    max-width: 250px;
}

.time-nav + .group-study-details {
    margin-top: 2rem;
}

.group-study-details {
    display: block;
    width: 100%;

    .current-study-group {
        background: #fff;
        margin-top: 1rem;
        padding: 0;
        box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05);
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        overflow: hidden;

        h3 {
            display: block;
            background: #35909A;
            width: 100%;
            margin-bottom: 0;
            padding: 0.75rem 1rem;
            font-weight: 500;
            color: #fff;
        }

        .members {
            display: flex;
            padding: 0.5rem 1rem;
            flex-wrap: wrap;
        }

        .member {
            display: flex;
            width: 13rem;
            margin: 0;
            padding: 1rem;
            align-items: flex-start;
            flex-wrap: wrap;

            .avatar {
                position: relative;
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;
                border-radius: 3rem;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                }
            }

            .member-details {
                width: calc(100% - 5rem);

                .member-name {
                    font-weight: 700;
                }

                .member-role {
                    display: flex;

                    img {
                        width: 1rem;
                        max-height: 1.375rem;
                        margin-right: 0.37rem;
                    }
                }
            }
        }

        .buttons {
            background: #f7f7f7;
            width: 100%;
            margin: 0;
            padding: 1rem;
            border-top: 1px solid #e8e8e8;
            text-align: right;
        }
    }

    .suggested-stations {
        margin-top: 2rem;

        .stations-list {
            display: flex;
            background: #fff;
            width: 100%;
            padding: 1rem;
            box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05);
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            overflow: hidden;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .suggested-station {
                display: flex;
                width: 100%;
                align-items: center;

                + .suggested-station {
                    margin-top: 1rem;
                    padding-top: 1rem;
                    border-top: 1px solid #e8e8e8;
                }

                .station-details {
                    .station-number {
                        margin: 0;
                        font-weight: 700;
                    }

                    .station-name {
                        margin: 0;
                    }

                    .suggestor {
                        margin: 3px 0;
                    }
                }

                .station-actions {
                    margin-left: auto;
                    padding-left: 1em;
                    white-space: nowrap;
                }
            }
        }
    }

    .suggested-playlists {
        margin-top: 2rem;

        .playlist {
            display: flex;
            background: #fff;
            width: 100%;
            padding: 1rem;
            box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05);
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            overflow: hidden;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            + .playlist {
                margin-top: 1em;
            }

            .playlist-stations {
                width: 100%;

                .playlist-station {
                    display: flex;
                    padding: 0.5em 0 0.5em 0.5em;

                    &:first-child {
                        margin-top: 0.5em;
                    }

                    .station-name {
                        display: inline;
                        margin-left: 1em;
                    }

                    .station-actions {
                        margin-left: auto;
                        padding-left: 1em;
                    }
                }
            }

            .suggestor {
                width: 100%;
            }
        }
    }

    .current-station {
        margin-top: 2rem;

        .current-station-details {
            display: flex;
            background: #fff;
            width: 100%;
            padding: 1rem;
            box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05);
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            overflow: hidden;
            justify-content: space-between;
            align-items: center;
        }
    }

    &.horizontal {
        display: flex;
        max-width: 100%;
        margin-top: 2rem;
        justify-content: center;
        flex-wrap: wrap;

        h2, h3 {
            text-align: center;
        }

        .currently-studying {
            width: 100%;

            .current-study-group {
                .members {
                    justify-content: center;
                }

                .member {
                    width: calc(100% / 5);
                    min-width: 13rem;
                }
            }
        }

        .suggested-stations,
        .suggested-playlists {
            width: calc((100% - 1rem) / 2);
        }

        .suggested-stations + .suggested-playlists {
            margin-left: 1rem;
        }
    }

    .suggested-stations,
    .suggested-playlists {
        .suggestor {
            display: block;
            text-align: left;
            font-size: 0.7em;
        }
    }

    .suggested-station-row,
    .suggested-playlist-row,
    .current-station-row {
        display: flex;
        margin: 1em 0;
        text-align: left;

        .suggested-station-details {
            max-width: 50%;
        }

        .suggested-station-actions,
        .suggested-playlist-actions,
        .current-station-actions {
            margin: auto 0 auto auto;
        }

        .current-station-actions {
            .ant-btn {
                margin-top: 0;
            }
        }
    }
}

#study-groups-page {
    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .ant-spin {
        display: block;
        margin: 40px auto;
    }

    .study-groups-head {
        display: flex;
        width: 100%;

        .search-groups {
            margin-right: 2em;
        }
    }

    .study-group-search {
        width: 100%;
        margin-top: 1rem;
    }

    .study-groups-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        &.has-sidebar {
            width: calc(100% - 24.75rem);
            margin-right: 2.5rem;
        }

        h2 {
            width: 100%;
            margin-right: 0.25em;
            margin-left: 0.25em;
        }

        .study-group {
            display: flex;
            background-color: $white;
            width: calc(100% / 3 - 0.5em);
            margin: 0.25em;
            padding: 0.75rem 1rem;
            border: 2px solid #35909A;
            border-radius: 5px;
            font-weight: bold;
            cursor: pointer;

            &.active {
                border: 2px solid #1890ff;
                color: #1890ff;

                .group-details {
                    .group-text {
                        .group-nickname {
                            color: #1890ff;
                        }
                    }

                    .group-controls {
                        .ant-btn {
                            &.leave-study-group {
                                background-color: #1890ff;
                                border-color: #1890ff;

                                &:hover {
                                    background: lighten(#1890ff, 5%);
                                }
                            }

                            &.edit-study-group {
                                border-color: #1890ff;
                                color: #1890ff;
                            }
                        }
                    }
                }
            }

            .group-details {
                display: flex;
                width: 100%;

                .studying {
                    font-size: 0.8em;
                    font-weight: initial;
                }

                .group-text {
                    width: calc(100% - 3.875rem);

                    .group-nickname {
                        margin: 0;
                        text-overflow: ellipsis;
                        font-size: 1.5em;
                        overflow: hidden;
                        color: #35909A;
                    }
                }

                .group-controls {
                    display: flex;
                    margin-left: auto;
                    flex-direction: column;

                    .ant-btn {
                        margin: 0.125rem 0;

                        &.play-btn {
                            display: flex;
                            padding: 0;
                            justify-content: center;
                            align-items: center;
                        }

                        &.start-study-group,
                        &.leave-study-group {
                            background: #35909a;
                            border-color: #35909a;
                            color: #fff;

                            &:hover {
                                background: lighten(#35909a, 5%);
                            }
                        }

                        &.edit-study-group {
                            border-color: #35909a;
                            color: #35909a;

                            &:hover {
                                background-color: rgba(#35909a, 0.06);
                            }
                        }

                        &.delete-study-group{
                            &:hover {
                                background-color: rgba(#ff4d4f, 0.06) !important;
                            }
                        }
                    }
                }
            }

            .group-study-buttons {
                margin-left: auto;
            }
        }
    }
}

.play-icon {
    max-width: 1rem;
    max-height: 0.75rem;
    margin-left: 0.125rem;
}

span + .play-icon {
    margin-left: 0.5rem;
}

.study-group-display {
    .study-details {
        display: flex;
        margin-bottom: 1em;

        .study-code {
            background-color: #35909A;
            color: $white;
            border-radius: 5px;
            font-size: 2em;
            margin: 0 auto;
            padding: 0.5em;
        }

        .start-study-group {
            border: 2px solid #35909A;
            color: #35909A;
            margin: auto 0;
        }
    }

    .group-nickname {
        display: flex;
        margin-bottom: 1em;

        input {
            margin-right: 0.5em;
        }
    }

    .ant-modal-footer {
        .ant-btn {
            &:not(.ant-btn-primary) {
                display: none;
            }
        }
    }
}

.tutorial-modal {
    top: 50%; left: 50%;
    width: 100%;
    max-width: 85.375rem;
    margin: 0;
    padding: 1rem;
    transform: translate(-50%, -50%);

    .slider {
        display: flex;

        .slider-image {
            display: flex;
            width: calc(100% - 20rem);
            align-items: center;

            img {
                width: 100%;
                box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.15);
            }
        }

        .slider-description {
            display: flex;
            width: 20rem;
            padding: 1rem 0 1rem 2rem;
            flex-direction: column;

            h3 {
                font-weight: 500;
                font-size: 150%;
                color: #35909A;
            }

            .description {
                max-width: 50rem;
                margin: 0 auto;
            }

            .buttons {
                margin-top: auto;

                button {
                    + button {
                        margin-left: 1rem;
                    }

                    svg {
                        width: 0.75rem;
                    }
                }
            }
        }
    }
}

.ant-message-notice {
    margin-top: 25px !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@import './breakpoints.scss';